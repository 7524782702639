<template>
  <v-container fluid>
    <v-card>
      <v-card-title>REPORTE EJECUCIÓN DE ACCIONES {{ year }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="5" class="d-flex justify-center">
            <v-progress-circular
              :rotate="100"
              :size="240"
              :width="25"
              :value="value"
              color="#43794E"
            >
              <span class="text-md-h4">{{ value.toFixed(2) }}%</span>
            </v-progress-circular>
          </v-col>
          <v-col cols="12" md="7" class="d-flex align-baseline flex-column">
            <!--- 3 div flex width same width-->
            <div class="d-flex justify-center" style="gap: 20px">
              <div class="d-flex flex-column justify-center">
                <strong class="text-md-h6 text-center" style="font-weight: bold"
                  >ACCIONES EVALUADAS</strong
                >
                <p class="text-md-h4 text-center mt-4">{{ actions.length }}</p>
              </div>
              <v-divider vertical></v-divider>
              <div
                class="d-flex flex-column justify-center"
                style="position: relative"
              >
                <p style="color: #43794e; font-size: 10px" class="text-right">
                  <b>AVANCE</b>
                </p>
                <!-- loop prop "actions" array <p>-->
                <div v-for="(action, index) in actions" :key="index">
                  <p>
                    <strong class="mr-14">ACCIÓN {{ index + 1 }}</strong>
                    {{ getFirstTrackingByAction(action).advance_percentage }}%
                  </p>
                  <v-divider></v-divider>
                </div>
              </div>
              <v-divider vertical></v-divider>
              <div
                class="d-flex flex-column justify-center"
                style="position: relative; min-width: 300px"
              >
                <p style="color: #43794e; font-size: 10px" class="text-center">
                  <b>VALOR EJECUTADO</b>
                </p>
                <div v-for="(action, index) in actions" :key="index">
                  <p class="text-center">
                    <strong>{{
                      getFirstTrackingByAction(action).advance_amount
                        | currencyCOP
                    }}</strong>
                  </p>
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <div class="d-flex justify-center flex-column" style="width: 100%">
              <p class="text-md-h5 mt-16 text-center">
                <strong class="mb-6">TOTAL DE EJECUCIÓN DEL PLAN</strong>
              </p>
              <p class="text-md-h5 mt-4 text-center">
                <span style="color: #43794e; font-size: 48px; font-weight: bold"
                  >{{ value.toFixed(2) }}%</span
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    year: {
      type: String,
      default: "2020",
    },
    value: {
      type: Number,
      default: 10,
    },
    actions: {
      default: [],
    },
  },
  data: () => ({
    selected: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
  }),
  created() {
    console.log(this.actions);
  },
  methods: {
    getFirstTrackingByAction(action) {
      return action.tracking.find((item) => item.year_at === this.year);
    },
  },
};
</script>
