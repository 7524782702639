<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>INFORMACIÓN GENERAL</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Territorio</span>
            <v-text-field
              v-model="plan.territory.descripcion"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Tipo de territorio</span>
            <v-text-field
              v-model="plan.territory.tipo_territorio.nombre"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Nivel de mandato</span>
            <v-text-field v-model="plan.mandate.name" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Año del mandato</span>
            <v-text-field v-model="plan.mandate_year" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <span class="green--text">Titulo</span>
            <v-text-field v-model="plan.name" readonly></v-text-field>
          </v-col>
          <v-col cols="12">
            <span class="green--text">Mandato</span>
            <p
              style="font-size: 15px; color: #222222"
              v-text="plan.description"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <span class="green--text">Categorías</span>
            <v-select
              v-model="plan.categories"
              :items="allPlanningReference?.categories"
              item-text="name"
              item-value="id"
              multiple
              readonly
              chips
            >
              <template #selection="{ item }">
                <v-chip
                  class="custom-tag"
                  color="#D3E9D7"
                  style="height: auto; margin-top: 11px; margin-left: 5px"
                  >{{ item.name }}</v-chip
                >
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Sistema responsable</span>
            <v-text-field
              v-model="plan.entity_responsible.name"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <span class="green--text">Fuente de finanzación</span>
            <v-text-field
              v-model="plan.funding_source.name"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" style="margin-top: 50px">
                <Actions readonly ref="actions"></Actions>
              </v-col>
              <v-col cols="12" style="margin-top: 50px">
                <Responsible readonly ref="responsible"></Responsible>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" style="margin-top: 50px">
            <h6 class="text-body-2" style="font-weight: bold">
              ESTIMACIÓN DE COSTO TOTAL
            </h6>
            <div class="mt-12 ml-12">
              <v-chip color="primary" large label>{{
                sumAllCosts | currencyCOP
              }}</v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-6">
      <v-toolbar flat>
        <v-toolbar-title style="font-weight: bold"
          >SEGUIMIENTO EJECUCIÓN DE ACCIONES</v-toolbar-title
        >
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-toolbar flat color="primary" dark>
          <template v-slot:extension>
            <v-tabs v-model="tab">
              <!-- for tabs from getYears()-->
              <v-tab
                v-for="year in getYears()"
                :key="year"
                :href="'#tab-' + year"
              >
                {{ year }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <!-- for tabs from getYears()-->
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="year in getYears()"
            :key="year"
            :value="'tab-' + year"
          >
            <v-card v-if="canHandle(year)">
              <v-card-text class="pa-11">
                <template>
                  <v-simple-table fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">ACCIÓN</th>
                          <th class="text-left">AVANCE DE EJECUCIÓN</th>
                          <th class="text-left">DESCRIPCIÓN DE LA EJECUCIÓN</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in $refs.actions.actions"
                          :key="index"
                          style="vertical-align: baseline"
                        >
                          <td
                            style="width: 33%"
                            v-if="advancesPercentage[year]"
                          >
                            <p class="mt-6">
                              {{ item.description }}
                            </p>
                            <strong>Valor estimado</strong>
                            <span style="color: #43794e">{{
                              item.estimatedCost | currencyCOP
                            }}</span>
                          </td>
                          <td
                            style="width: 30%"
                            v-if="advancesPercentage[year]"
                          >
                            <v-row class="mt-4">
                              <v-card-text>
                                <v-subheader class="mb-2" style="color: #43794e"
                                  >Porcentaje avance de esta acción</v-subheader
                                >
                                <v-slider
                                  v-model="advancesPercentage[year][item.id]"
                                  :thumb-size="24"
                                  thumb-label="always"
                                  :readonly="hasTracking(item.id, year)"
                                >
                                  <template v-slot:thumb-label="{ value }">
                                    <span class="pa-4 mx-4">{{ value }}%</span>
                                  </template>
                                </v-slider>
                              </v-card-text>
                              <v-card-text v-if="!hasTracking(item.id, year)">
                                <v-subheader class="mb-7" style="color: #43794e"
                                  >Valor ejecutado hasta el momento de esta
                                  acción (Opcional)</v-subheader
                                >
                                <v-slider
                                  v-model="advancesAmount[item.id]"
                                  :thumb-size="24"
                                  thumb-label="always"
                                  :max="item.estimatedCost"
                                  :min="0"
                                  class="custom-slider"
                                  :readonly="hasTracking(item.id, year)"
                                >
                                  <template
                                    v-slot:thumb-label="{ value }"
                                    class="custom-slider-thumb"
                                  >
                                    <span class="pa-4">{{
                                      value | currencyCOP
                                    }}</span>
                                  </template>
                                </v-slider>
                                <template>
                                  <v-expansion-panels
                                    class="mb-3"
                                    v-if="!hasTracking(item.id, year)"
                                  >
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        Colocar valor más preciso
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-currency-field
                                          v-model="advancesAmount[item.id]"
                                          label="Valor ejecutado"
                                          prefix="$"
                                          suffix="COP"
                                          :disabled="hasTracking(item.id, year)"
                                          :rules="[
                                            (v) =>
                                              clearNumberFormat(v) <=
                                                item.estimatedCost ||
                                              'El valor no puede ser mayor al valor estimado',
                                          ]"
                                        ></v-currency-field>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </template>
                              </v-card-text>
                              <v-card-text v-else>
                                <v-subheader class="mb-7" style="color: #43794e"
                                  >Valor ejecutado hasta el momento de esta
                                  acción (Opcional)</v-subheader
                                >
                                <v-slider
                                  v-model="advancesAmount[year][item.id]"
                                  :thumb-size="24"
                                  thumb-label="always"
                                  :max="item.estimatedCost"
                                  :min="0"
                                  class="custom-slider"
                                  :readonly="hasTracking(item.id, year)"
                                >
                                  <template
                                    v-slot:thumb-label="{ value }"
                                    class="custom-slider-thumb"
                                  >
                                    <span class="pa-4">{{
                                      value | currencyCOP
                                    }}</span>
                                  </template>
                                </v-slider>
                                <template>
                                  <v-expansion-panels
                                    class="mb-3"
                                    v-if="!hasTracking(item.id, year)"
                                  >
                                    <v-expansion-panel>
                                      <v-expansion-panel-header>
                                        Colocar valor más preciso
                                      </v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-currency-field
                                          v-model="
                                            advancesAmount[year][item.id]
                                          "
                                          label="Valor ejecutado"
                                          prefix="$"
                                          suffix="COP"
                                          :disabled="hasTracking(item.id, year)"
                                          :rules="[
                                            (v) =>
                                              clearNumberFormat(v) <=
                                                item.estimatedCost ||
                                              'El valor no puede ser mayor al valor estimado',
                                          ]"
                                        ></v-currency-field>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </template>
                              </v-card-text>
                            </v-row>
                          </td>
                          <td
                            style="width: 37%"
                            v-if="advancesPercentage[year]"
                          >
                            <v-textarea
                              v-model="advancesDescription[year][item.id]"
                              label="Descripción de la ejecución"
                              solo
                              rows="5"
                              :disabled="hasTracking(item.id, year)"
                            ></v-textarea>
                          </td>
                        </tr>
                        <v-divider></v-divider>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-flex
                  class="justify-center mb-5"
                  v-if="!hasTrackingAllYearsByActions() && itSBeenAYearFromPlanStartDate"
                >
                  <v-btn
                    class="align-self-center"
                    color="primary"
                    rounded
                    @click="saveAdvances"
                    >GUARDAR EVALUACIÓN Y GENERAR REPORTE</v-btn
                  >
                </v-flex>
              </v-card-actions>
            </v-card>
            <v-card v-else>
              <v-card-text>Los seguimientos a las acciones del plan se realizan anualmente durante 4 años. <br>
                El próximo seguimiento a este plan es el:  <span class="text-h6 primary--text" >{{plan.actions[0]?.tracking[0]?.next_date_tracking || plan.first_date_tracking}}</span></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- render v-snackbar with  validationErrors-->
        <v-snackbar v-model="snackbar" :timeout="6000" color="warning" shaped>
          No puede guardar el seguimiento de ejecución de acciones hasta que
          diligencie todos los campos requeridos
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text >
    </v-card>
    <v-card v-if="hasTrackingAllYearsByActions()">
      <v-divider></v-divider>
      <Report
        :year="lastTrackingYear"
        :value="avgAdvancesPercentage"
        :actions="plan.actions"
      ></Report>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>
<!-- make component for tracking fetch data from planning service getPlanById -->
<script>
import { getPlan, addTracking } from "@/services/Planning";
import { mapGetters } from "vuex";
import Actions from "@/views/Planning/components/Actions";
import Responsible from "@/views/Planning/components/Responsible";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { toCamelCase } from "@/utils/mapper";
import Report from "@/views/Planning/components/Report";
export default {
  name: "Tracking",
  components: {
    Actions,
    Responsible,
    Report,
  },
  data() {
    return {
      tab: null,
      plan: {
        territory: {
          descripcion: "",
          tipo_territorio: {
            nombre: "",
          },
        },
        mandate: {
          name: "",
        },
        mandate_year: "",
        name: "",
        description: "",
        categories: [],
        entity_responsible: {
          name: "",
        },
        funding_source: {
          name: "",
        },
        actions: [],
        created_at: "",
      },
      totalEstimatedCost: 0,
      advancesAmount: {},
      advancesPercentage: {},
      advancesDescription: {},
      snackbar: false,
    };
  },
  validations() {
    let validations = {
      advancesPercentage: {
        required,
      },
      advancesDescription: {
        required,
      },
    };
    this.$refs.actions.actions.forEach((action) => {
      validations.advancesPercentage[this.currentYear] = {};
      validations.advancesPercentage[this.currentYear][action.id] = {
        required,
      };
      validations.advancesDescription[this.currentYear] = {};
      validations.advancesDescription[this.currentYear][action.id] = {
        required,
      };
    });
    return validations;
  },
  computed: {
    ...mapGetters(["allPlanningReference"]),
    currentYear() {
      return new Date().getFullYear().toString();
    },
    lastTrackingYear() {
      if (this.plan.actions[0]?.tracking?.length > 0) {
        return this.plan.actions[0].tracking[0].year_at;
      } else {
        return this.currentYear;
      }
    },
    avgAdvancesPercentage() {
      let total = 0;
      this.plan.actions.forEach((action) => {
        console.log(action);
        let tracking = action.tracking.find(
          (tracking) => tracking.year_at === this.lastTrackingYear
        );
        if (tracking) {
          total += tracking.advance_percentage;
        }
      });
      return total / this.plan.actions.length;
    },

    validationErrors() {
      let errors = {};
      this.$refs.actions.actions.forEach((action) => {
        errors[this.currentYear] = {};
        errors[this.currentYear][action.id] = {};
        if (!this.advancesPercentage[this.currentYear][action.id]) {
          errors[action.id].push("El porcentaje de avance es requerido");
        }
        if (!this.advancesDescription[this.currentYear][action.id]) {
          errors[action.id].push("La descripción es requerida");
        }
      });
      return errors;
    },
    sumAllCosts: {
      get() {
        return this.totalEstimatedCost.toString();
      },
      set(actions) {
        const result = actions.reduce((acc, action) => {
          return Number(acc) + Number(action.estimatedCost);
        }, 0);
        this.totalEstimatedCost = result;
      },
    },
    itSBeenAYearFromPlanStartDate() {
      const startDate = new Date(this.plan.created_at);
      const currentDate = new Date();
      const diffTime = Math.abs(currentDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 365;
    },
  },
  methods: {
    getYears() {
      if (this.plan.actions.length > 0) {
        const years =
          this.plan.actions[0]?.tracking?.map((item) => item.year_at) || [];
        const sortedYears = years.sort((a, b) => a - b);
        if (sortedYears[sortedYears.length - 1] !== this.currentYear) {
          sortedYears.push(this.currentYear);
        }
        return sortedYears;
      }
      return [];
    },
    fillModel() {
      const years = this.getYears();
      if (years.length) {
        years.forEach((year) => {
          this.advancesAmount[year] = {};
          this.advancesPercentage[year] = {};
          this.advancesDescription[year] = {};
          this.plan.actions.forEach((action) => {
            const tracking = action.tracking.find(
              (item) => item.year_at === year
            );
            if (tracking) {
              this.advancesAmount[year][tracking.action_id] =
                tracking?.advance_amount || 0;
              this.advancesPercentage[year][tracking.action_id] =
                tracking?.advance_percentage || 0;
              this.advancesDescription[year][tracking.action_id] =
                tracking?.description || "";
            } else {
              this.advancesAmount[year][action.action.id] = 0;
              this.advancesPercentage[year][action.action.id] = 0;
              this.advancesDescription[year][action.action.id] = "";
            }
          });
        });
      }
    },
    hasTrackingAllYearsByActions() {
      const years = this.getYears();
      const actions = this.plan.actions;
      let hasTracking = true;
      years.forEach((year) => {
        actions.forEach((action) => {
          const tracking = action.tracking.find(
            (item) => item.year_at === year
          );
          if (!tracking) {
            hasTracking = false;
          }
        });
      });
      return hasTracking;
    },
    hasTracking(actionId, year) {
      return Boolean(
        this.plan.actions
          .find((action) => action.action.id === actionId)
          ?.tracking?.find((item) => item.year_at === year)
      );
    },
    clearNumberFormat(value) {
      return Number(value?.toString()?.replace(/[.]/g, "") || 0);
    },
    canHandle(year){
      if(year === this.currentYear && this.itSBeenAYearFromPlanStartDate){
        return true
      }
      else if(year !== this.currentYear){
        return true
      }
    },
    async getPlan() {
      try {
        const response = await getPlan(this.$route.params.planId);
        this.plan = response.data;
        this.$refs.actions.setActions(
          toCamelCase(this.plan.actions.map((action) => action.action))
        );
        this.sumAllCosts = this.$refs.actions.actions;
        this.$refs.responsible.setResponsible(
          toCamelCase(this.plan.responsible)
        );
        this.fillModel();
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Ups!",
          "Ah ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    async saveAdvances() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.snackbar = true;
          return;
        }
        const advances = this.$refs.actions.actions.map((action) => {
          return {
            action_id: action.id,
            advance_percentage:
              this.advancesPercentage[this.currentYear][action.id],
            advance_amount: this.advancesAmount[action.id],
            description: this.advancesDescription[this.currentYear][action.id],
          };
        });
        console.log(advances);
        await addTracking(advances);
        Swal.fire(
          "Se ha guardado la información correctamente",
          "",
          "success"
        );
        this.getPlan();
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Ups!",
          "Ah ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  },
  created() {
    this.getPlan();
  },
};
</script>
<style lang="scss">
/** get parent element by child class .custom-slider-thumb */
.custom-slider .v-slider__thumb-label {
  transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(0deg) !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  width: auto !important;
  border-radius: 0;
  div {
    transform: rotate(0deg) !important;
  }
}
/** set rotate 0deg into .custom-slider child .v-slider__thumb-label */
.custom-slider .v-slider__thumb-label::before {
  transform: rotate(0deg);
}
.theme--light.v-messages {
  display: none !important;
}
</style>
